// 引入请求方法
import { fun_PostAction } from "@/api";
// 默认导出
export default {
  // data
  data: () => {
    return {
      platformName: null, // 交易中心名称
      shareState: null, // 是否公布状态
      list: [],
      newList: [],
      // 详情本地数据
      detailList: [
        {
          title: "招标项目名称：",
          key: "projectName",
          value: ""
        },
        {
          title: "招标项目编号：",
          key: "projectNo",
          value: ""
        },
        // {
        //   title: "招标控制价：",
        //   key: "controlPrice",
        //   value: ""
        // },
        {
          title: "标段（包）名称：",
          key: "bidSectionName",
          value: ""
        },
        {
          title: "标段（包）编号：",
          key: "bidSection",
          value: ""
        },
        {
          title: "投标截止时间：",
          key: "meetingStartDate",
          value: ""
        },
        {
          title: "开标时间：",
          key: "meetingStartDate",
          value: ""
        }
      ],
      // 列表数据
      data: [],
      // 表头数据
      tableColumns: [
        {
          label: "投标人/供应商",
          prop: "corpName"
        },
        {
          label: "投标报价（万元）",
          prop: "biddingPrice"
        },
        {
          label: "投标工期（月）",
          prop: "biddingPeriod"
        },
        {
          label: "项目经理",
          prop: "projectManager"
        },
        {
          label: "项目经理/负责人证书标号",
          prop: "managerCertno"
        },
        {
          label: "投标保证金",
          prop: "bidSecurityInfo"
        }
        // {
        //   label: "中招公信链开标存证记录",
        //   slotName: "op"
        // }
      ],
      token: null
    };
  },
  // 模板编译完成
  mounted() {
    // 详情
    this.fun_GetList();
    this.token = sessionStorage.getItem("token");
  },
  // 自定义方法
  methods: {
    /**
     * @author 滕威
     * @description 详情
     */
    async fun_GetList() {
      // 获取id
      const id = this.$route.query.id;
      // 获取交易中心名称
      this.platformName = this.$route.query.platformName;
      let flag = "";
      let tradeCenterNo = "";
      // 是否是查询当前交易中心下的所有数据过来的
      if (this.$route.query.flag) {
        flag = this.$route.query.flag;
        tradeCenterNo = this.$route.query.tradeCenterNo;
      }
      // 请求获取res
      let res = await fun_PostAction(
        "/bidOpenInfo/getProjectBidOpeningResults?id=" + id + "&flag=" + flag + "&tradeCenterNo=" + tradeCenterNo
      );
      // 解构res.data
      const { result, data, msg } = res.data;
      // 判断请求是否成功
      if (result) {
        // 获取公布状态
        this.shareState = data.shareState;
        // 判断是否已公布
        if (data.projectInfoInfo) {
          // 获取详情
          let projectInfoInfo = data.projectInfoInfo;
          // 回显详情
          this.detailList.map(e => {
            e.value = projectInfoInfo[e.key];
          });
        }
        if (this.shareState === "01") {
          // 循环每一个投标单位的数据 获取开标大厅的数据
          data.list.forEach(entity => {
            // 转换为json字符串
            let openBidContent = JSON.parse(entity.openBidContent);
            // 放入数组
            this.list.push(openBidContent);
          })
          // 获取集合的第一项
          let obj = this.list[0];
          // 遍历第一项得到列表的头
          for (const key in obj) {
            if(key != 'id'){
              // 深度复制 获取表头
              this.newList = [...this.newList, key];
            }
          }
        }
      } else {
        // 请求失败，提示错误信息
        if (msg) this.$message.error(msg);
      }
    },
    /**
     * @author 滕威
     * @description 返回
     */
    fun_GoBack() {
      // 返回上一页
      this.$router.back();
    }
  }
};
